import React from 'react';

import { useReactTable, getCoreRowModel, ColumnDef, flexRender, Row } from '@tanstack/react-table';

import {
  CenteredRow,
  StyledBodyTr,
  StyledContainer,
  StyledTable,
  StyledTableContainer,
  StyledTbody,
  StyledTd,
  StyledTh,
  StyledThead,
} from './table.styles';
import { ActionBar } from './components/ActionBar';
import { Pagination } from './components/Pagination';
import { NoResults } from './components/NoResults';
import { LoadingSpinner } from '../status-indicators/LoadingSpinner/LoadingSpinner';

type WithPaginationProps = {
  currentPage: number;
  totalCount: number;
  nextPage: () => void;
  previousPage: () => void;
  showPagination: true;
};

type WithoutPaginationProps = {
  currentPage?: number;
  totalCount?: number;
  nextPage?: () => void;
  previousPage?: () => void;
  showPagination?: false;
};

type PaginationProps = WithPaginationProps | WithoutPaginationProps;

export type Props<T extends object> = {
  data: T[];
  columns: ColumnDef<T & { id: string }>[];
  className?: string;
  isFixed?: boolean;
  TableActions?: React.ComponentType<{ selectedItems: Row<any>[]; unselectRows: () => void }>;
  error?: {
    hasError: boolean;
    errorMessage?: string;
  };
  enableRowSelection?: boolean | ((row: Row<T>) => boolean);
  clearFilters?: () => void;
  hasFiltersApplied?: boolean;
  loading?: boolean;
} & PaginationProps;

export function Table<T extends object>({
  columns: defaultColumns,
  data,
  className,
  isFixed = false,
  currentPage,
  totalCount,
  nextPage,
  previousPage,
  TableActions,
  error,
  enableRowSelection = true,
  clearFilters,
  hasFiltersApplied,
  showPagination = true,
  loading = false,
}: Props<T & { id: string }>) {
  const [rowSelection, setRowSelection] = React.useState({});

  const columns = React.useMemo<ColumnDef<T & { id: string }>[]>(() => defaultColumns, []);

  const defaultData = React.useMemo(() => [], []);

  const table = useReactTable({
    data: data ?? defaultData,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  return (
    <StyledContainer $isFixed={isFixed}>
      <StyledTableContainer $isFixed={isFixed}>
        <StyledTable $isFixed={isFixed} className={className} $isLoading={loading}>
          <StyledThead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <StyledTh
                      key={header.id}
                      colSpan={header.colSpan}
                      $noPadding={!!header.column.columnDef.meta?.styles?.header?.noPadding}
                      $hasDefinedColumnSize={
                        !!header.column.columnDef.meta?.styles?.definedColumnSize
                      }
                      $width={header.column.getSize()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </StyledTh>
                  );
                })}
              </tr>
            ))}
          </StyledThead>
          <StyledTbody>
            {error?.hasError && !loading ? (
              <StyledBodyTr $isSelected={false}>
                <CenteredRow colSpan={columns.length}>
                  <NoResults
                    clearFilters={clearFilters}
                    hasFiltersApplied={hasFiltersApplied}
                    message={error.errorMessage ?? 'There was an error loading the data.'}
                  />
                </CenteredRow>
              </StyledBodyTr>
            ) : data.length === 0 && !loading ? (
              <StyledBodyTr $isSelected={false}>
                <CenteredRow colSpan={columns.length}>
                  <NoResults
                    clearFilters={clearFilters}
                    hasFiltersApplied={hasFiltersApplied}
                    message="No results found."
                  />
                </CenteredRow>
              </StyledBodyTr>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => (
                  <StyledBodyTr key={row.id} $isSelected={row.getIsSelected()}>
                    {row.getVisibleCells().map((cell) => {
                      const { columnDef } = cell.column;
                      return (
                        <StyledTd
                          key={cell.id}
                          $noPadding={!!columnDef.meta?.styles?.cell?.noPadding}
                          $textAlign={columnDef.meta?.styles?.cell?.textAlign}
                          $hasDefinedColumnSize={!!columnDef.meta?.styles?.definedColumnSize}
                          $width={cell.column.getSize()}
                        >
                          {flexRender(columnDef.cell, cell.getContext())}
                        </StyledTd>
                      );
                    })}
                  </StyledBodyTr>
                ))}
                {loading && (
                  <StyledBodyTr $isSelected={false}>
                    <CenteredRow colSpan={columns.length}>
                      <LoadingSpinner size="36px" />
                    </CenteredRow>
                  </StyledBodyTr>
                )}
              </>
            )}
          </StyledTbody>
        </StyledTable>

        {Object.keys(rowSelection).length > 0 && (
          <ActionBar table={table} isFixed={isFixed} TableActions={TableActions} />
        )}
      </StyledTableContainer>
      {showPagination && (
        <Pagination
          currentPage={currentPage!}
          totalCount={totalCount!}
          nextPage={nextPage!}
          previousPage={previousPage!}
        />
      )}
    </StyledContainer>
  );
}
